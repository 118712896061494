<template>
	<errorContainer :error="erreur" :warning="warning">
		<div class="animated fadeIn pb-2">
			<b-row>
				<b-col sm="12" md="12" lg="12" xl="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]" class="px-0">
						{{ FormMSG(215, 'Purchase Order Details') }}
					</h1>
				</b-col>
			</b-row>
			<div class="container-layout details-document-package p-0">
				<b-card no-body class="card-border-blue-light pb-4">
					<b-row class="back-with-title cursor-pointer d-flex align-items-center py-1" :class="{ 'mt-16': $isPwa() }" @click="$router.go(-1)">
						<b-col cols="8" class="d-flex align-items-center pl-0">
							<div class="icon">
								<ArrowLeft color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
							</div>
							<h2>{{ caption1 }}</h2>
						</b-col>
						<b-col cols="4" class="inline-flex align-items-center text-right py-0">
							<div class="date-title pr-3">
								<div class="wrap-status justify-content-end">
									<div
										:class="`status ${classStatus(curPo.validated)}`"
										style="font-size: 0.825rem; border: 0.005rem solid rgba(226, 230, 235, 0.65)"
									>
										{{ curPo.validatedStatus }}
									</div>
								</div>
							</div>
							<div class="name-title pr-3">{{ curPo.fullName }} <span class="divider-dot"></span> {{ curPo.functionName }}</div>
							<div v-if="useEntryForCrewPo" class="d-flex align-items-center justify-content-end w-100 pr-3">
								<span v-if="!isFilmSingle" class="border-encoded p-1"
									>{{ FormMSG(40, 'Encoded by: ') }}<b>{{ curPo.encoder.firstName }} {{ curPo.encoder.name }}</b>
								</span>
							</div>
							<div class="header-name-title pr-3">
								{{ dateToStringFormat(curPo.requestDate) }} <span class="divider-dot"></span>
								<strong>{{ formatAmount(curPo.amount) }}</strong>
							</div>
							<div class="header-name-title pr-3">
								<strong>{{ FormMSG(875, 'N°') }}:</strong> {{ curPo.poReference }}
							</div>
						</b-col>
					</b-row>
					<div class="form pt-3 px-1">
						<b-row>
							<b-col md="3" v-if="curPo.images && curPo.images.length > 0" class="pt-28">
								<b-button block variant="outline-success" @click="showPicture(curPo.images)">
									<component :is="getLucideIcon('Image')" :size="16" />
									{{ `${FormMSG(425, 'View')} ${curPo.images.length} ${FormMSG(428, 'pictures')}` }}
								</b-button>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12" md="12">
								<b-form-group v-if="curPo.comment.length > 0" :label="FormMSG(23, 'Comment:')" label-for="comment">
									<b-form-textarea :disabled="true" id="comment" v-model="curPo.comment" rows="2"></b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="pt-3">
							<b-col cols="12" xl="12">
								<MobileViewsValidatePoDetailsList v-if="$screen.width < 992" :items="PoItems" @card-clicked="rowClicked" />
								<b-table
									v-if="$screen.width >= 992"
									:hover="hover"
									responsive="sm"
									:items="PoItems"
									style="text-align: center"
									:fields="poItemFields"
									:current-page="currentPage"
									@row-clicked="rowClicked"
									sticky-header="700px"
									:per-page="perPage"
									:head-variant="hv"
									bordered
									small
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col v-if="canEditPo" lg="2" class="mb-3">
								<b-button variant="outline-primary" block @click="handleClickEditPO" class="d-flex justify-content-center align-items-center">
									<component :is="getLucideIcon(ICONS.EDIT.name)" :size="20" class="mr-2" />
									<div style="margin-top: 2px">{{ FormMSG(168, 'Edit Purchase Order') }}</div>
								</b-button>
							</b-col>
							<b-col lg="3" class="mb-3">
								<b-button block @click="downloadXls" variant="outline-primary" class="d-flex justify-content-center align-items-center mr-3">
									<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" />
									<div style="margin-top: 1px">{{ this.FormMSG(222, 'Download this Purchase Order') }}</div>
								</b-button>
							</b-col>
							<b-col lg="3" class="mb-3">
								<b-button
									block
									@click="sendXlsByEmail"
									variant="outline-primary"
									:disabled="isLoadingSendMail"
									class="d-flex justify-content-center align-items-center"
								>
									<b-spinner v-show="isLoadingSendMail" small></b-spinner>
									<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" v-if="!isLoadingSendMail" />
									<div style="margin-top: 1px">{{ FormMSG(500, 'Send me this purchace order by Email') }}</div>
								</b-button>
							</b-col>
							<b-col lg="2" class="mb-3">
								<b-button
									@click="validatePO"
									size="md"
									variant="outline-success"
									block
									class="d-flex justify-content-center align-items-center"
								>
									<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="20" class="mr-2" />
									<div style="margin-top: 1px">{{ this.FormMSG(24, 'Validate Purchase Order') }}</div>
								</b-button>
							</b-col>
							<b-col lg="2">
								<b-button
									class="d-flex justify-content-center align-items-center"
									@click="ModalRejectPO = true"
									size="md"
									variant="outline-danger"
									block
								>
									<component :is="getLucideIcon(ICONS.X_CIRCLE.name)" :size="20" class="mr-2" />
									<div style="margin-top: 1px">{{ this.FormMSG(25, 'Reject Purchase Order') }}</div>
								</b-button>
							</b-col>
						</b-row>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(26, 'Success!')"
							class="modal-success"
							v-model="successModalValidated"
							@ok="successModalValidated = false"
							ok-variant="success"
							ok-only
							modal-class="mui-animation"
							:fade="false"
						>
							{{ this.FormMSG(27, 'The purchase order has been validated') }}
						</b-modal>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(28, 'Refuse this purchase order')"
							class="modal-danger"
							v-model="ModalRejectPO"
							@ok="this.finaliseExpenseReject"
							ok-variant="danger"
							ok-only
							modal-class="mui-animation"
							:fade="false"
						>
							<b-form-group :label="FormMSG(29, 'Enter your comment:')" label-for="comment" :label-cols="4">
								<b-form-textarea
									id="comment"
									v-model="rejectComment"
									:placeholder="this.FormMSG(30, 'Enter your comment')"
									rows="3"
								></b-form-textarea>
							</b-form-group>
						</b-modal>
					</div>
					<PoService
						v-model="showPoService"
						:edit-data="poToEdit"
						for-screen-validation
						@closed="handlePOServiceClosed"
						@submitted="handlePOServiceSubmitted"
						@refresh="handlePOServiceRefresh"
					/>
				</b-card>
			</div>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(21211, 'Success!')"
			class="modal-success"
			v-model="successModal"
			@ok="successModal = false"
			ok-variant="success"
			ok-only
			modal-class="mui-animation"
			:fade="false"
		>
			{{ FormMSG(601, 'The purchace order has been sent to your Email address') }}
		</b-modal>
		<script-export-modal
			v-model="isScriptPoReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="po"
			@script-export-modal:loading="handleMailSent"
			@script-export-modal:closed="poRerpotOnClosed"
		/>
	</errorContainer>
</template>
<script>
// import axios from 'axios';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import MobileViewsValidatePoDetailsList from '@/components/MobileViews/ValidatePoDetails/List';
import { mapActions } from 'vuex';
import { isNil } from '@/shared/utils';
import { classStatus } from '@/shared/global-status';
import moment from 'moment';
import { getValidations } from '@/cruds/validations.crud';
import { getPO, getPoItems, validatePurchaseOrder, rejectPurchaseOrder } from '@/cruds/po-item.crud';
import { Edit2, ArrowLeft } from 'lucide-vue';
import PoService from '@/components/PoService/Main';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import { purchaseOrderReport } from '@/cruds/export.crud';
import globalMixin from '@/mixins/global.mixin';
import { rendCurrency } from '~helpers';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';

export default {
	name: 'ValidatePoDetails',
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		MobileViewsValidatePoDetailsList,
		Edit2,
		PoService,
		ArrowLeft,
		ScriptExportModal
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			fileDownloadLink: null,
			warning: '',
			erreur: {},
			curMonthStr: '',
			oneItem: '',
			PoItems: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			successModalValidated: false,
			ModalRejectPO: false,
			expenseItemComment: '',
			successModalRejectedPo: false,
			rejectComment: '',
			filter: '',
			isLoading: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curPo: {
				id: 0,
				requestDate: '',
				amount: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			currentValidationLevel: 0,
			showPoService: false,
			poToEdit: null,
			isScriptPoReportOpen: false,
			poTypeId: 8,
			scripts: [],
			isLoadingSendMail: false,
			customReport: null
		};
	},
	computed: {
		canEditPo() {
			return store.canEditPo();
		},
		poItemFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(50, 'Id'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(51, 'Description'),
					sortable: true
				},
				{
					key: 'costCenterAndCategoryName',
					label: this.FormMSG(52, 'Category'),
					sortable: true,
					formatter: (value, key, item) => {
						return `${item.categoryCostCenter} - ${item.categoryName}`;
					}
				},
				{
					key: 'quantity',
					label: this.FormMSG(53, 'Quantity'),
					formatter: (value) => {
						return value;
					},
					sortable: true
				},
				{
					key: 'price',
					label: this.FormMSG(36, 'Unit price'),
					formatter: (v) => rendCurrency(v),
					sortable: true
				},
				{
					key: 'total',
					label: this.FormMSG(37, 'Total'),
					formatter: (v) => rendCurrency(v),
					sortable: true
				}
			];
		},
		caption1: function () {
			if (isNil(this.curPo)) return '';

			return this.FormMSG(10, 'Purchase order details from') + ' #' + this.curPo.id;
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				'background-color': 'grey',
				fontSize: '14px'
			};
			if (this.curPo.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curPo.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curPo.validated == 8) {
				myObj.color = 'green';
			} else if (this.curPo.validated == 4 || this.curPo.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		useEntryForCrewPo() {
			return store.state.myProject.useEntryForCrewPo;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.poTypeId, true);
			const id = +this.curPo.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(26, 'Success'), this.FormMSG(54, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(26, 'Success'), this.FormMSG(54, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.poTypeId, true);
			const id = +this.curPo.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id);
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async managePoReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_PO, script.id, script.fileName);

			await purchaseOrderReport(
				{
					subjectId,
					sendEmail,
					activeStatus: true
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		async handleClickEditPO() {
			this.showPoService = true;
		},
		handlePOServiceClosed() {
			this.showPoService = false;
		},
		async handlePOServiceSubmitted(payload) {
			if (payload) {
				await this.reloadData();
				this.showPoService = false;
			}
		},
		async handlePOServiceRefresh(payload) {
			if (payload) {
				await this.reloadData();
			}
		},
		async getRoleValidation() {
			const validations = await getValidations();
			const userId = store.userID();
			const departmentId = store.state.myProfile.department;

			let haveValidationAllDepartment = null;
			let haveValidationSingleDepartment = null;

			for (let i = 0; i < validations.length; i++) {
				const element = validations[i];
				if (parseInt(element.department, 10) === -1 && parseInt(element.user.id, 10) === userId) {
					haveValidationAllDepartment = element;
				} else if (parseInt(element.user.id, 10) === userId && parseInt(element.department, 10) === departmentId) {
					haveValidationSingleDepartment = element;
				}
			}

			if (!isNil(haveValidationSingleDepartment) && !isNil(haveValidationAllDepartment)) {
				if (haveValidationSingleDepartment.validationLevelPo > haveValidationAllDepartment.validationLevelPo) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			} else {
				if (!isNil(haveValidationSingleDepartment)) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else if (!isNil(haveValidationAllDepartment)) {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			}
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		rowClicked(item) {
			//console.log("item.comment :", item.comment);
		},
		async finaliseExpenseReject() {
			this.ModalRejectPO = false;
			const purchaseOrderId = +this.$route.params.id;
			const comment = this.rejectComment;

			await rejectPurchaseOrder(purchaseOrderId, comment)
				.then(async (record) => {
					await this.getNotifications();
					await this.reloadData();
					// put updated value in curPo structure
					// console.log('result from rejectPO:', result)
					this.curPo.total = record.total;
					this.curPo.comment = record.comment;
					this.curPo.validated = record.validated;
					this.curPo.validatedStatus = this.validatedText(this.curPo.validated);
					// console.log('curPo from rejectExp:', this.curPo)
					this.rejectComment = '';
					this.successModalRejectedPo = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async validatePO() {
			const purchaseOrderId = +this.$route.params.id;

			await validatePurchaseOrder(purchaseOrderId, '')
				.then(async (record) => {
					await this.getNotifications();
					await this.reloadData();
					this.curPo.total = record.total;
					this.curPo.comment = record.comment;
					this.curPo.validated = record.validated;
					this.curPo.validatedStatus = this.validatedText(this.curPo.validated);
					this.successModalValidated = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.curPo = store.getCurPO();
			// console.log("this.curPo in firstTimeInitialisation:", this.curPo)
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(poItemData) {
			// setting row color
			poItemData.validatedStatus = this.validatedText(poItemData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			poItemData._rowVariant = this.validatedColor(poItemData.validated);
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			this.isLoading = true;
			const purchaseOrderId = +this.$route.params.id;

			this.poToEdit = await getPO(purchaseOrderId);

			await getPoItems(purchaseOrderId)
				.then((records) => {
					records.forEach(this.updateTZdataEach);
					this.PoItems = records;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		dateToString(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		poRerpotOnClosed() {
			this.isScriptPoReportOpen = false;
		},
		handleMailSent(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
			}
		},
		formatAmount(amount) {
			return rendCurrency(amount);
		}
	},
	async created() {
		if (this.curPo.requestDate.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.getRoleValidation();
		await this.reloadData();
	}
};
</script>
<style lang="scss" scoped>
input[type='text'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	/*background-color: grey;
      color: white;*/
}
.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}
.name-title {
	// color: #3c7442;
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
.date-title {
	display: inline-flex;
	justify-content: center !important;
	font-weight: 500;
	padding: 4px 0 4px 0;
	// font-size: 1.75rem;
	color: rgba(6, 38, 62, 0.74);
}
</style>
